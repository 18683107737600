<template>
  <div class="app">
    <header-view
      v-if="showHF"
      class="app__header"
    />
    <main-view class="app__main" />
    <footer-view
      v-if="showHF"
      class="app__footer"
    />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Main from '@/views/Main.vue';

export default {
  name: 'App',
  components: {
    'header-view': Header,
    'footer-view': Footer,
    'main-view': Main
  },
  data () {
    return {
      showHF: true
    };
  },

  created () {
    if (window.location.search) {
      this.showHF = false;
    }
  }

};
</script>

<style lang="scss" scoped>
.app{
  height: 100%;
  display: flex;
  flex-direction: column;
  &__main{
    flex: 1;
  }
}
</style>
