<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__columns">
          <div class="footer__row">
            <img
              src="@/assets/icon/asiapay-white.svg"
              alt="logo"
            >
          </div>
          <div class="footer__row">
            <div class="footer__text">
              © {{ new Date().getFullYear() }} ТОО «FFIN Payments»
            </div>
          </div>
          <div class="footer__row">
            <div class="footer__text">
              БИН: 180 840 007 833
            </div>
          </div>
          <div class="footer__row">
            <div class="footer__text">
              Зарегистрировано в Управлении Юстиции Бостандыкского района Департамента юстиции города Алматы от 07.08.2018
            </div>
          </div>
        </div>
        <div class="footer__columns">
          <div class="footer__row">
            <a
              href="https://asiapay.kz/public_offer"
              target="_blank"
              class="footer__link"
            >
              Публичная оферта 
            </a>
          </div>
          <div class="footer__row">
            <a
              href="https://asiapay.kz/terms"
              target="_blank"
              class="footer__link"
            >
              Договор присоединения </a>
          </div>
          <div class="footer__row">
            <a
              href="https://asiapay.kz/rules_payment_organization"
              class="footer__link"
              target="_blank"
            >Правила платежной организации</a>
          </div>
        </div>

        <div class="footer__columns">
          <div class="footer__row">
            <div class="footer__text">
              БЦ Evolution Бухар Жырау бульвар, 26/1, Коктем 1, Бостандыкский район, Алматы, 050021
            </div>
          </div>
          <div class="footer__row">
            <div class="footer__text">
              Время работы: <br>10:00 - 19:00 (GMT+6)
            </div>
          </div>
          <div class="footer__row">
            <div>
              <a
                href="tel:8(778)746 38 01"
                class="footer__text"
              >
                +7 (778) 746-38-01
              </a>
            </div>
            <div>
              <a
                href="tel:8(727)344 1162"
                class="footer__text"
              >
                +7 (727) 344-11-62
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
.footer{
    background: #F6F7F7;
    border-top: 1px solid #D0D7E3;
    padding: 50px 0 70px 0;
    &__inner{
        display: flex;
        justify-content: space-between;
    }
    &__columns {
        flex: 0 0 30%;
    }
    &__text{
        color: #667481;
        text-decoration: none;
        font-size: 14px;
        line-height: 18px;
    }
    &__link{
        color: #667481;
        text-decoration: none;
        font-size: 14px;
    }
    &__title{
        font-size: 16px;
        color: #ffffff;
    }
    &__row{
        padding: 12px 0;
    }
}
@media (max-width: 768px) {
    .footer {
        &__inner{
            flex-direction: column;;
        }
        &__columns{
            margin-bottom: 40px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
</style>