<template>
  <div class="receipt">
    <div class="receipt__inner">
      <div class="receipt__status">
        <div class="status">
          <div class="status__text">
            {{ payment.message }}
          </div>
          <div class="status__icon">
            <icon
              :icon-name="payment.status"
            />
          </div>
        </div>
      </div>
      <div v-if="payment.status === 'success'">
        <div class="receipt__download">
          <div
            class="download"
            @click="downloadPdf"
          >
            <div class="download__inner">
              <icon
                icon-name="download"
                class="download__icon"
              />
              <div class="download__text">
                Скачать чек
              </div>
            </div>
          </div>
        </div>

        <div :class="['receipt__ticket', { 'receipt__ticket--full': fromUrl}]">
          <div
            id="receipt"
            ref="receipt"
            class="ticket"
          >
            <div class="ticket__inner">
              <div
                v-if="showLogo"
                class="ticket__logo"
              >
                <icon icon-name="logo" />
              </div>
              <div class="ticket__line" />
              <div class="ticket__total">
                <div class="ticket__item">
                  <div class="ticket__text ticket__text--gray">
                    №{{ payment.data.payment.operation_id }}
                  </div>
                  <div class="ticket__text ticket__text--gray">
                    {{ payment.data.payment.completed_at }}
                  </div>
                </div>
                <div class="ticket__item ticket__item--noindent">
                  <div class="ticket__text ticket__text--big">
                    Итого
                  </div>
                  <div class="ticket__text ticket__text--big">
                    <div>{{ payment.data.payment.total_amount }} ₸</div>
                  </div>
                </div>
              </div>
              <div class="ticket__line" />
              <div class="ticket__details">
                <div
                  v-if="serviceType"
                  class="ticket__item"
                >
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Услуга
                  </div>
                  <div class="ticket__text ticket__text--bold ticket__text--bold ticket__item-text">
                    {{ serviceType }}
                  </div>
                </div>  
                <div
                  v-if="payment.data.payment.service"
                  class="ticket__item"
                >
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Сервис
                  </div>
                  <div class="ticket__text ticket__text--bold">
                    {{ payment.data.payment.service }}
                  </div>
                </div>
                <div class="ticket__item">
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Отправитель
                  </div>
                  <div class="ticket__text ticket__text--bold ticket__item-text ticket__text--bold">
                    {{ payment.data.payment.abonent }}
                  </div>
                </div>
                <div
                  v-if="recipientByType"
                  class="ticket__item"
                >
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Получатель
                  </div>
                  <div
                    class="ticket__text ticket__text--bold"
                  >
                    {{ recipientByType }}
                  </div>
                </div>
                <div class="ticket__item">
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Сумма
                  </div>
                  <div class="ticket__text ticket__text--bold ticket__text--bold ticket__item-text">
                    {{ payment.data.payment.bill_amount }}
                  </div>
                </div>  
                <div class="ticket__item">
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Комиссия
                  </div>
                  <div class="ticket__text ticket__text--bold ticket__text--bold ticket__item-text">
                    {{ payment.data.payment.commission_amount }}
                  </div>
                </div>  
                <div class="ticket__item">
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Номер чека
                  </div>
                  <div class="ticket__text ticket__text--bold">
                    {{ payment.data.payment.check_number }}
                  </div>
                </div>
                <div
                  v-if="showOuterOperationId"
                  class="ticket__item"
                >
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Внешний ID операции
                  </div>
                  <div class="ticket__text ticket__text--bold">
                    {{ payment.data.payment.operation_outer_id }}
                  </div>
                </div>
              </div>
              <div
                v-if="showEMData"
                class="ticket__line"
              />
              <div
                v-if="showEMData"
                class="ticket__details"
              >
                <div
                  v-if="showEmOperator"
                  class="ticket__item"
                >
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Оператор ЭД
                  </div>
                  <div class="ticket__text">
                    <div class="ticket__text--bold">
                      {{ payment.data.payment.em_operator }}
                    </div>
                    <div class="ticket__text--gray">
                      {{ payment.data.payment.em_operator_bin }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="showIssuer"
                  class="ticket__item"
                >
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Эмитент
                  </div>
                  <div class="ticket__text">
                    <div class="ticket__text--bold">
                      {{ payment.data.payment.issuer }}
                    </div>
                    <div class="ticket__text--gray">
                      {{ payment.data.payment.issuer_bin }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="showMerchant"
                  class="ticket__item"
                >
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Мерчант
                  </div>
                  <div class="ticket__text">
                    <div class="ticket__text--bold">
                      {{ payment.data.payment.service }}
                    </div>
                    <div class="ticket__text--gray">
                      {{ payment.data.payment.merchant_identifier }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="payment.data.payment.support_service_phone"
                class="ticket__line"
              />
              <div
                v-if="payment.data.payment.support_service_phone"
                class="ticket__details"
              >
                <div class="ticket__item ticket__item--noindent">
                  <div class="ticket__text ticket__text--gray ticket__text--left">
                    Служба поддержки
                  </div>
                  <a
                    :href="supportPhone"
                    class="ticket__text ticket__text--violet ticket__text--bold"
                  >
                    {{ payment.data.payment.support_service_phone || '+7 (727) 344 11 62' }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="receipt__btn">
      <ap-button
        v-if="isMobile && showBtn"
        type="primary"
        full
        @click="backForm"
      >
        Вернуться
      </ap-button>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Icon from './iconPack.vue';
import apButton from './apButton.vue';
export default {
  name: 'Receipt',
  components: {
    Icon,
    apButton
  },
  props: {
    payment: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      fromUrl: window.location.search,
      showBtn: true
    };
  },
  computed: {
    showIssuer: function () {
      return this.payment.data.payment.issuer || this.payment.data.payment.issuer_bin;
    },
    showEmOperator: function () {
      return this.payment.data.payment.em_operator || this.payment.data.payment.em_operator_bin;
    },
    showMerchant: function () {
      return this.payment.data.payment.transaction_type === 'forward' && this.payment.data.payment.merchant_identifier;
    },

    serviceType: function () {
      return this.payment.data.payment.transaction_type_human;
    },
    recipientByType: function () {
      const { payment } = this.payment.data;

      const msg = payment.transaction_type === 'forward' 
        ? payment.service_name_human ?? payment.service 
        : payment.recipient;
      
      return msg;
    },

    showOuterOperationId: function () {
      const { payment } = this.payment.data;

      return !['trans_card2sim', 'trans_sim2card', 'trans_sim2sim', 'transfer', 'refill'].includes(payment.transaction_type) && payment.operation_outer_id;
    },

    showEMData: function () {
      const { payment } = this.payment.data;

      return !['trans_card2sim'].includes(payment.transaction_type  ) && (this.showEmOperator || this.showIssuer || this.showMerchant);
    },

    supportPhone: function () {
      return `tel:${this.payment.data.payment.support_service_phone || '+7 (727) 344 11 62' }`;
    },

    showLogo: function () {
      return this.payment.data.showLogo;
    },
  },

  created () {
    if (this.fromUrl) {
      this.showBtn = false;
    }
  },
  
  methods: {
    async downloadPdf() {
      const receiptW = document.getElementById('receipt').offsetWidth;
      const receiptH = document.getElementById('receipt').offsetHeight;
      const margin = 10;
      const pdfW = receiptW + (margin * 2);
      const pdfH = receiptH + (margin * 2);
      const canvasW = receiptW;
      const canvasH = receiptH;

      const doc = new jsPDF('mm', 'pt', [pdfW, pdfH]);
      html2canvas(this.$refs.receipt, {dpi: 300, scale: 3}).then(function(canvas) {
        const img = canvas.toDataURL('image/jpeg', 1);
        doc.addImage(img, 'JPEG', margin, margin, canvasW, canvasH - 35);
        doc.setProperties({
          title: 'Чек AsiaPay'
        });
        // Переменные для определение ios мобильных устройств
        let ua = window.navigator.userAgent;
        let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        let webkit = !!ua.match(/WebKit/i);
        let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        // Условия для скачивание чека в ios safari
        if (iOSSafari) {
          const blob = doc.output('blob');
          const downloadFile = (blob, fileName) => {
            const link = document.createElement('a');
            // create a blobURI pointing to our Blob
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
          };
          downloadFile(new Blob([blob]), 'Чек AsiaPay.pdf');
        } else {
          let reader = new FileReader();
          let blob = doc.output('blob');
          let out = new Blob([blob], { type: 'application/pdf' });
          reader.onload = function() {
            window.location.href = reader.result;
          };
          reader.readAsDataURL(out);
          var fileURL = URL.createObjectURL(out);
          var a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = 'Чек AsiaPay.pdf';
          document.body.appendChild(a);
          a.click();
        }

      });
    },

    isString(x) {
      return Object.prototype.toString.call(x) === '[object String]';
    },
    backForm () {
      this.$emit('back');
    }


  }
};
</script>

<style scoped lang="scss">
.receipt {
    max-width: 380px;
    width: 100%;
    
    &__status{
      margin: 10px 0;
    }
    &__download{
      margin: 30px 0;
    }
    &__footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-text{
            font-size: 10px;
            color: #a4a4a4;
        }
        &-icon{
            max-width: 25%;
        }
    }
  &__btn{
    margin-top: 25px;
  }
  &__ticket{
    max-height: 360px;
    height: 100%;
    overflow: auto;
    padding: 0 10px;
  }
  &__ticket--full{
    max-height: 100%;
    height: 100%;
  }
  &__ticket::-webkit-scrollbar-thumb{
    min-height: 5px;
  }
  &__ticket::-webkit-scrollbar{ 
    width: 7px;
  }
}
.status{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__text{
        color: #08131D;
        font-weight: 600;
        font-size: 21px;
    }
    &__icon{
      display: block;
    }
}
.download{
    background-color: #6059F4;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    padding: 12px 0;
    cursor: pointer;
    border-radius: 8px;
    &__inner{
      display: flex;
      justify-content: center;
      align-content: center;
      width: max-content;
    }
    &__icon{
        margin-right: 14px;
    }
    &__text{
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
    }
}
.ticket{
    position: relative;
    max-width: 364px;
    width: 100%;
    background: url('@/assets/img/check.png');
    background-size: 363px 100%; 
    background-repeat: no-repeat;
    margin: 0 auto;
    &__inner{
      padding: 0px 0 30px 4px;
    }
    &__svg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
    }
    &__content{
        padding: 28px 16px;
        position: relative;
        width: 100%;
        height: 100%;
    }
    &__logo{
        width: 100%;
        padding: 28px 24px 4px 24px;
        margin-bottom: 24px;
    }
    &__text{
        text-align: right;
        color: $black;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 110%;
        letter-spacing: -0.005em;
        text-decoration: none;
        &--gray{
          color: $gray;
        }
        &--violet{
          color: $violet;
        }
        &--bold{
            font-weight: 500;
        }
        &--big{
            font-weight: 700;
            font-size: 20px;
        }
        &--left{
          text-align: left;
        }   
    }
    &__item{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        &-text{
            width: 50%;
        }
        &--noindent{
            margin: 0;
        }
    }
    &__line{
        height: 2px;
        width: 100%;
        background-color: #eee;
        display: block;
        // margin-bottom: 18px;
    }
    &__footer{
        &--text{
            width: max-content;
            margin: 0 auto;
        }
    }
    &__total{
        background-color: $light-gray;
        padding: 24px 16px;
    }
    &__details{
        padding: 24px 16px;
    }
}
</style>
