<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <div
          class="logo header__logo"
          @click="toMain"
        >
          <img
            src="@/assets/icon/asiapay.svg"
            alt="logo"
            class="logo"
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    toMain () {
      location.href='https://asiapay.kz/';
    }
  }
};
</script>

<style lang="scss" scoped>
.header{
    background: #F6F7F7;
    border-bottom: 1px solid #D0D7E3;
    &__inner{
      padding: 26px 0;
    }
    &__logo{
      width: max-content;
    }
    &__logo:hover{
      cursor: pointer;
    }

}
</style>