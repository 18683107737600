<template>
  <div :class="['apInput', { 'apInput--error': hasErrorText }]">
    <div class="apInput__inner">
      <label
        v-if="label" 
        class="apInput__label"
      >
        {{ label }}
      </label>
      <div :class="['apInput__field', {'apInput__field--border': border}]">
        <input
          class="apInput__input"
          type="number"
          :placeholder="placeholder"
          :disabled="disabled"
          :maxlength="length"
          :max="max"
          :value="value"
          @input="onInput"
        >
        <div v-if="currency">
          ₸
        </div>
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-show="hintOrError"
        class="apInput__hint"
      >
        {{ hintOrError }}
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'ApInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    card: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  computed: {
    hasError() {
      return this.errors.length > 0;
    },
    labelOrErrorHint() {
      if (this.hasError) {
        return this.errors[0];
      }
      return this.label;
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    }
  }
};
</script>
<style lang="scss" scoped>
.apInput{
  display: flex;
  flex-direction: column;
  &__input{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    border: none;
    &:focus{
      outline: none;
    }
    &:disabled{
      background-color: #ffffff;
    }

  }
  &__hint {
      font-size: 12px;
      margin-top: 6px;
      color: rgba(0,0,0, .6);
  }
  &__field{
    display: flex;
    overflow: hidden;
    padding: 10px;
    align-items: center;
    width: 100%;
    position: relative;
    border: 1px solid $secondary-gray;
    border-radius: 6px;
    line-height: 22px;

    &--border{
      border: none;
    }
     &__input::placeholder {
      line-height: 24px;
    }
  }
  &__label{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.72);
    margin-bottom: 6px;
    display: block;
  }
  &__icon{
    display: block;
    height: 24px;
    width: 24px;
  }
}
</style>