<template>
  <button
    class="button"
    :class="[
      `button--${type}`,
      {'button--lg': lg},
      {'button--pay': pay},
      {'button--full': full},
    ]"
    :disabled="disable"
  >
    <slot>
      Button
    </slot>
  </button>
</template>

<script>
export default {
  name: 'ApButton',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    pay: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  $bg-btn-default: #ffffff;
  $bg-btn-default-active: #e4e4e4;
  $color-default: #4f4f4f;
  $bg-btn-primary: #3681C7;
  $bg-btn-pay: #ff7226;
  $color-primary: #fff;
  $bg-btn-disabled: #d3d3d3;
  $color-disabled: #fff;

  .button{
    border: none;
    cursor: pointer;
    display: inline-block;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    margin-bottom: 1em;
    transition: background .2s;
    min-width: 175px;
    font-family: inherit;

    &--primary {
      background:$primary-purple;
      cursor: pointer;
      color: #ffffff;
      font-weight: bold;
      border-radius: 8px;
      &[disabled] {
        background: $bg-btn-disabled;
        color: $color-disabled;
        pointer-events: none;
      }
    }
    &--lg{
      font-size: 21px;
      font-weight: 600;
      height: 60px;
      min-width: 280px;
    }
    &--full {
      display: block;
      width: 100%;
    }
    &--outlined {
      background: transparent;
      color: $primary-purple;
    }

}
</style>
