import axios from 'axios';

const config = {
  baseURL: '/',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
};

export const request =  ({
  method = 'get',
  url = '/',
  data = {},
  headers = {},
  params = {}
}) => {
  config.url = url;
  config.method = method;
  config.data = data;
  config.params = params;
  config.headers = {
    ...config.headers,
    ...headers
  };

  return axios(config);
};
