<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <div
          v-if="showForm"
          class="main__item main__left"
        >
          <div class="form">
            <div class="form__inner">
              <div class="form__item form__title">
                Поиск платежа
              </div>
              <div class="form__item form__phone-input">
                <ap-input-tel
                  id="phone"
                  :value="phoneNumber"
                  class="main-form__input"
                  label="Введите номер абонента"
                  :disabled="isNeedNewRequest"
                  @input="phoneNumber = $event"
                />
              </div>
              <div class="form__item form__id-input">
                <ap-input
                  id="payment"
                  :value="paymentNumber"
                  class="main-form__input"
                  label="Введите № операции полученный в SMS"
                  :disabled="isNeedNewRequest"
                  @input="paymentNumber = $event"
                />
              </div>
              <div class="form__item form__btn">
                <ap-button
                  type="primary"
                  small
                  :disable="disableBtn"
                  @click="submit"
                >
                  Получить чек
                </ap-button>

                <ap-button
                  v-if="isNeedNewRequest"
                  type="outlined"
                  small
                  @click="newRequest"
                >
                  Новый запрос
                </ap-button>
              </div>
            </div>
          </div>
        </div>
        <div class="main__item main__right">
          <receipt
            v-if="showReceipt"
            :payment="receipt"
            class="main__receipt"
            :is-mobile="isMobile"
            @back="back"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { request } from '@/api/request';
import apInput from '@/components/apInput.vue';
import apInputTel from '@/components/apInputTel.vue';
import apButton from '@/components/apButton.vue';
import Receipt from '@/components/Receipt.vue';
export default {
  components: {
    apInput,
    apInputTel,
    apButton,
    Receipt
  },
  data () {
    return {
      phoneNumber: '',
      paymentNumber: '',
      showReceipt: false,
      receipt: {},
      isNeedNewRequest: false,
      isMobile: false,
      showForm: true,
      showFH: true,
      fromUrl: window.location.search
    };
  },
  computed: {
    disableBtn () {
      return !(this.paymentNumber.length > 0 && this.phoneNumber.replace(/\s/g, '').length === 10) || Object.keys(this.receipt).length;
    },
  },
  created () {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && window.innerWidth < 768) {
      this.isMobile = true;
    }
    this.getCheckByUrl();
  },
  methods: {
    async submit () {
      this.isNeedNewRequest = true;
      let abonent = this.phoneNumber.replace(/\s/g, '');

      if (!this.fromUrl) {
        abonent = '7' + abonent;
      }

      const options = {
        operator: 'asiapay',
        reference: this.paymentNumber,
        abonent
      };

      const { data } = await request({
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL,
        data: options,
      });

      if (data) {
        if (this.isMobile) {
          this.showForm= false;
        }
        this.receipt = { ...data };
        this.showReceipt = true;
      }

    },

    back () {
      this.showForm = true;
      this.showReceipt = false;
    },

    getCheckByUrl () {
      if (this.fromUrl) {
        /* your code here */
        let [abonent, operation] = window.location.search.substring(1).split('&');
        this.showForm = false;
        if (abonent && operation) {
          this.phoneNumber = abonent;
          this.paymentNumber = operation;
          this.submit();
        }
      }
    },
    newRequest () {
      this.isNeedNewRequest = false;
      this.showReceipt = false;
      this.receipt = {};
      this.paymentNumber = '';
      this.phoneNumber = '';
    },
  }
};
</script>
<style lang="scss" scoped>
.main{
  background-color: #ffffff;
  &__inner{
      display: flex;
      min-height: 705px;
      height: 100%;
    }
    &__item{
      padding-top: 60px;
      height: 100%;
      flex: 1;
    }
    &__left{
      border-right: 1px solid #D0D7E3;
    }
    &__form {
      display: flex;
      flex-direction: column;
    }
    &__receipt{
      margin: 0 auto;
    }
    &__title{
      margin-bottom: 40px;
      color: #4f4f4f;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
    }
}
.form{
  max-width: 397px;
  width: 100%;
  &__title{
    margin-bottom: 48px;
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
  }
  &__phone-input{
    margin-bottom: 30px;
  }
  &__id-input{
    margin-bottom: 40px;
  }
}
@media (max-width: 769px) {

  /* CSS */
.main{
  height: auto;
  &__inner{
    flex-direction: column;
  }
  &__item{
    padding-top: 30px;
    flex: 1;
  }
  &__left{
    border: none;
  }
}
.main-form{
  &__btn{
    flex-direction: column;
  }
}

}
</style>
