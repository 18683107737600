<template>
  <div class="tel">
    <div :class="['apInputTel', {'apInputTel--error': hasErrorText}]">
      <div class="apInputTel__inner">
        <label
          v-if="label" 
          class="apInputTel__label"
        >
          {{ label }}
        </label>
        <div class="apInputTel__field">
          <span class="apInputTel__code">+7</span>
          <input
            :id="id"
            v-mask="'### ### ## ##'"
            class="apInputTel__input"
            :disabled="disabled"
            :value="value"
            @input="onInput"
          >
        </div>
        <transition
          name="fade" 
          mode="out-in"
        >
          <div
            v-if="hintOrError"
            class="apInputTel__hint"
          >
            {{ hintOrError }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: 'ApInputTel',
  directives: { mask },
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    onlyCountries: {
      type: Array,
      default: () => ([]),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
    defaultCountry: {
      type: String,
      default: '',
    },
    maxLen: {
      type: Number,
      default: 15,
    },
  },
  emits: ['input'],
  data() {
    return {};
  },
  computed: {
    hasError() {
      return this.errors.length > 0;
    },
    labelOrErrorHint() {
      if (this.hasError) {
        return this.errors[0];
      }

      return this.label;
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    }
  }
};
</script>


<style lang="scss" scoped>
  .apInputTel{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  &__input{
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    border: none;
    &:focus{
      outline: none;
    }
    &::placeholder{
      padding-left: 2px;
      color: $primary-gray;
    }
    &:disabled{
      background-color: #ffffff;
    }
  }
  &__code{
    margin-right: 3px;
    line-height: 22px;
  }
  &__hint {
      margin-top: 6px;
      font-size: 12px;
      color: rgba(0,0,0, .6);
  }
  &__field{
    display: flex;
    overflow: hidden;
    padding: 10px;
    align-items: center;
    width: 100%;
    position: relative;
    border: 1px solid $secondary-gray;
    border-radius: 6px;
    line-height: 22px;
  }
  &__label{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: $secondary-black;
    margin-bottom: 6px;
    display: block;
  }
  &__icon{
    display: block;
    height: 24px;
    width: 24px;
  }
}
</style>
